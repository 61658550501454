import React, { useEffect, useState } from 'react';
import { AppLayout } from 'src/components/layouts';
import LogisticTable from './components/LogisticTable';
import LogisticsAnalysis from './components/LogisticsAnalysis';
import AssignRider from './components/AssignRider'; // Import AssignRider component
import AdminService from 'src/services/admin.services';
import { Logistic } from 'src/services/types';
import BackButton from 'src/components/BackButton';

const adminService = new AdminService();
const ITEMS_PER_PAGE = 40; // Adjusted to 40 per the API requirement

const LogisticsManagement: React.FC = () => {
  const [data, setData] = useState<Logistic[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [filteredData, setFilteredData] = useState<Logistic[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [activeTab, setActiveTab] = useState<'table' | 'analysis' | 'assignRider'>('table'); // Add AssignRider tab

  const getLogisticsManagement = async (page: number) => {
    try {
      setIsLoading(true);
      // Assuming longitude, latitude, and max_distance are available here:
      const longitude = 8.6753;
      const latitude = 9.0820;
      const max_distance = 100000000000000n; // Use appropriate distance value

      // Fetch logistics data based on current page and size
      const logisticsData = await adminService.getLogisticsManagement(page, longitude, latitude, max_distance);

      // Assuming logisticsData is an array, set it to data
      setData(logisticsData);
      setFilteredData(logisticsData); // Assuming the data is already filtered initially
    } catch (error) {
      console.error('Error fetching logistics data:', error);
      setError('Error fetching logistics data.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]); // Dependency on currentPage so it fetches data when the page changes
  
  const fetchData = () => {
    getLogisticsManagement(currentPage); // Fetch data when currentPage changes
  };

  const handleSearch = (searchTerm: string) => {
    if (searchTerm) {
      setFilteredData(
        data.filter((logistic) =>
          logistic.description?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false
        )
      );
    } else {
      setFilteredData(data);
    }
    setCurrentPage(1); // Reset to first page on new search
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  return (
    <AppLayout>
      <BackButton />
      <div className="bg-white shadow-lg rounded-md p-8">
        <h2 className="text-xl font-semibold mb-4">Logistics Management</h2>
        <div className="mb-4">
          <button
            className={`mr-4 py-2 px-4 rounded-md ${activeTab === 'table' ? 'bg-amber-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setActiveTab('table')}
          >
            Table View
          </button>
          <button
            className={`mr-4 py-2 px-4 rounded-md ${activeTab === 'analysis' ? 'bg-amber-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setActiveTab('analysis')}
          >
            Analysis View
          </button>
          <button
            className={`py-2 px-4 rounded-md ${activeTab === 'assignRider' ? 'bg-amber-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setActiveTab('assignRider')}
          >
            Assign Rider
          </button>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <p>Loading...</p>
          </div>
        ) : error ? (
          <div className="flex justify-center items-center h-full">
            <p className="text-red-500">{error}</p>
          </div>
        ) : activeTab === 'table' ? (
          <LogisticTable
            data={filteredData}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            itemsPerPage={ITEMS_PER_PAGE}
          />
        ) : activeTab === 'analysis' ? (
          <LogisticsAnalysis logistics={filteredData} />
        ) : (
          <AssignRider />
        )}
      </div>
    </AppLayout>
  );
};

export default LogisticsManagement;
