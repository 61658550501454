import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import AdminService from 'src/services/admin.services';
import { Order } from 'src/services/types';
import TabComponent from './TabComponent ';
import Modal from './Modal';
import removeRedEye from '../../assets/icons/removeRedEye.svg';
import html2canvas from 'html2canvas';

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import DeliveryLocation from '../deliverylocation/DeliveryLocation';
import { Link } from 'react-router-dom';


//import { LocationData } from 'src/services/types';
ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const adminService = new AdminService();

// Declare locally or in a shared file
type OrderStatus = 
  | "delivered" 
  | "pending" 
  | "cancelled" 
  | "confirmed" 
  | "declined" 
  | "all" 
  | "location";

type OrderFilter = "all" | "location" | OrderStatus;


// Define LocationData type
type LocationData = {
  latitude: number;
  longitude: number;
};



const NotificationWrapper: React.FC = () => {
 // State declarations
 const [orders, setOrders] = useState<Order[]>([]);
 const [currentPage] = useState<number>(1);
 const [filteredData, setFilteredData] =useState<Order[]>([]);

 const [pageNumber, setPageNumber] = useState(1);
 const [totalPages, setTotalPages] = useState(10); // Default total pages
 
 const ordersPerPage = 40; // Display 40 orders per page
 const [loading, setLoading] = useState<boolean>(true);
 const [searchTerm, setSearchTerm] = useState<string>('');
 const [successMessage] = useState<string | null>(null);
 const [error, setError] = useState<string | null>(null);
 const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
 const [filter] = useState<string>('day');
 const [isUpdating, setIsUpdating] = useState<boolean>(false);
 const [filterStatus, setFilterStatus] = useState<OrderStatus>('all');
 const [locationData, setLocationData] = useState<LocationData | null>(null);
 const [activeTab, setActiveTab] = useState<number>(0);const [currentFilter, setCurrentFilter] = useState<OrderFilter>('all');




// Filter orders by coordinates
  const filterByCoordinates = (
    orders: Order[],
    longitude?: number,
    latitude?: number,
  maxDistance: number = 100000000
  ): Order[] => {
    if (!longitude || !latitude) return orders;

    return orders.filter((order) => {
      const { longitude: orderLongitude, latitude: orderLatitude } = order;
      if (orderLongitude && orderLatitude) {
        const distance = getDistanceFromLatLonInMeters(latitude, longitude, orderLatitude, orderLongitude);
        return distance <= maxDistance;
      }
      return false;
    });
  };

// Calculate distance between two coordinates
  const getDistanceFromLatLonInMeters = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const R = 6371e3;
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a = Math.sin(Δφ / 2) ** 2 +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  const fetchOrders = async (page: number, filter: OrderFilter) => {
    setLoading(true);
    try {
      const fetchedOrders = await adminService.getOrders(
        page,
        ordersPerPage,
        searchTerm.trim() !== '' ? searchTerm : undefined,  // Pass searchTerm to backend if it exists
        filter === 'location' || filter === 'all' ? undefined : filter,
        filter === 'location' ? locationData?.longitude : undefined,
        filter === 'location' ? locationData?.latitude : undefined
      );
  
      if (Array.isArray(fetchedOrders)) {
        setOrders(fetchedOrders);
        console.log(`Fetched orders for page ${page}:`, fetchedOrders);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (err) {
      console.error('Error fetching orders:', err);
      setError('Failed to fetch orders. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  


// Apply search filter
const applySearchFilter = (orders: Order[]) => {
  if (searchTerm.trim() !== '') {
    const lowerCaseSearchTerm = searchTerm.trim().toLowerCase();
    return orders.filter((order) =>
      (order._id && order._id.toLowerCase().includes(lowerCaseSearchTerm)) ||
      (order.order_code && order.order_code.toLowerCase().includes(lowerCaseSearchTerm)) ||
      (order.restaurant_name && order.restaurant_name.toLowerCase().includes(lowerCaseSearchTerm)) ||
      (order.date_delivered &&
        new Date(order.date_delivered).toLocaleDateString().includes(searchTerm)) ||
      (order.created_at &&
        new Date(order.created_at).toLocaleDateString().includes(searchTerm)) ||
      (order.agent && order.agent.toLowerCase().includes(lowerCaseSearchTerm)) ||
      (order.order_status && order.order_status.toLowerCase().includes(lowerCaseSearchTerm)) ||
      (order.user && order.user.toLowerCase().includes(lowerCaseSearchTerm))
    );
  }
  return orders; // No filtering if the searchTerm is empty
};

// Paginate filtered orders
const paginatedOrders = applySearchFilter(orders).map((order, index) => ({
  ...order,
  serialNumber: (pageNumber - 1) * ordersPerPage + (index + 1),
  uniqueKey: `${order._id}-${index}`,
}));



useEffect(() => {
  if (filterStatus === 'location' && navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setLocationData({ latitude, longitude });
      },
      (error) => console.error('Error fetching geolocation:', error),
      { enableHighAccuracy: true }
    );
  }
}, [filterStatus]);





useEffect(() => {
  const fetchTotalOrders = async () => {
    try {
      const total = await adminService.getTotalOrders();
      setTotalPages(Math.ceil(total / ordersPerPage)); // Ensure correct total pages
    } catch (err) {
      console.error('Error fetching total orders:', err);
    }
  };
  fetchTotalOrders();
}, []);

const handlePageChange = (page: number) => {
  if (page >= 1 && page <= totalPages) {
    setPageNumber(page);
  }
};






// Fetch new orders when pageNumber changes
useEffect(() => {
  fetchOrders(pageNumber, currentFilter);
}, [pageNumber, currentFilter, locationData]);


  // Search input handler
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
  };
  



useEffect(() => {
  fetchOrders(pageNumber, filterStatus);
}, [filterStatus]);


const openModal = (order: Order) => {
  setSelectedOrder(order);
};

const closeModal = () => {
  setSelectedOrder(null);
};
  // Function to prepare data for download (as CSV)
  const prepareDownloadData = (orders: Order[]) => {
    const csvRows = [
      ['Order Code', 'Restaurant Name', 'Date Delivered', 'Order Status'], // Header row
      ...orders.map(order => [
        order._id,
        order.order_code,
        order.restaurant_name,
        order.subtotal,
        order.total,
        order.delivery_fee,
        order.tax,
       
        order.items.map((item: { meal: { name: any; }; qty: any; }) => `${item.meal.name} (${item.qty})`).join(', '),
        order.user,
        order.order_status,
        order.payment_method,
        new Date(order.date_delivered).toLocaleDateString(),
        new Date(order.pickup_time).toLocaleDateString(),
        new Date(order.enroute_time).toLocaleDateString(),
        new Date(order.created_at).toLocaleDateString(),
   
        order.agent,
        order.agent_delivered_order_upload,
        order.delivery_location,
       
        order.discount.order,
        order.discount.delivery,
        order.voucher,
        
        order.note,
        order.user_received_order_upload
      ])
    ];
  
    const csvContent = csvRows.map(row => row.join(',')).join('\n');
  
    // Create a download link for CSV
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `orders_${filter}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  



  const downloadInvoice = () => {
    const captureElement = document.getElementById('order-details');

    if (captureElement && selectedOrder) {
      html2canvas(captureElement, {
        useCORS: true,
        scale: 2,
        logging: true,
      })
        .then(canvas => {
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = `Order_${selectedOrder._id}.png`;
          link.click();
        })
        .catch(error => {
          console.error('Error capturing the element:', error);
        });
    }
  };



  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;



  const handleCancelOrder = async (order: Order): Promise<void> => {
    try {
      setIsUpdating(true);
      console.log("Cancelling Order:", order._id);
  
      if (["delivered", "cancelled"].includes(order.order_status ?? "")) {
        alert(`Cannot update this order as it is already "${order.order_status}".`);
        return;
      }
      
  
      // Update order status to "cancelled"
      await adminService.updateOrders(order._id, { order_status: "cancelled" });
      alert(`Order status updated to "cancelled".`);
    } catch (error: unknown) {
      handleOrderError(error, "cancel");
    } finally {
      setIsUpdating(false);
    }
  };
  
  const handleAcceptOrder = async (order: Order): Promise<void> => {
    try {
      setIsUpdating(true);
      console.log("Accepting Order:", order._id);
  
      // Prevent updates to already delivered orders
      if (order.order_status === "confirmed") {
        alert("Cannot accept this order as it has already been confirmed.");
        return;
      }
      
      // Update order status to "confirmed"
      await adminService.updateOrders(order._id, { order_status: "confirmed" });
      alert(`Order status updated to "confirmed".`);
    } catch (error: unknown) {
      handleOrderError(error, "accept");
    } finally {
      setIsUpdating(false);
    }
  };
  
  const handleDeclineOrder = async (order: Order): Promise<void> => {
    try {
      setIsUpdating(true);
      console.log("Declining Order:", order._id);
  
      // Prevent updates to already delivered orders
      if (order.order_status === "delivered") {
        alert("Cannot decline this order as it has already been delivered.");
        return;
      }
  
      // Update order status to "declined"
      await adminService.updateOrders(order._id, { order_status: "declined" });
      alert(`Order status updated to "declined".`);
    } catch (error: unknown) {
      handleOrderError(error, "decline");
    } finally {
      setIsUpdating(false);
    }
  };
  
 
  const handleOrderError = (error: unknown, action: string): void => {
    console.error(`Error trying to ${action} the order:`, error); // Log the full error for debugging
  
    if (typeof error === "object" && error !== null && "error" in error) {
      // Handle API error responses
      const errorMessage = (error as { error: string }).error;
  
      if (errorMessage.toLowerCase().includes("already comfirmed")) {
        alert("This order has already been delivered and cannot be updated.");
      } else if (errorMessage.toLowerCase().includes("already cancelled")) {
        alert("This order has already been cancelled and cannot be updated.");
      } else {
        alert(`Failed to ${action} the order: ${errorMessage}`);
      }
    } else if (error instanceof Error) {
      // Handle standard JavaScript errors
      alert(`Status: ${error.message}`);
    } else {
      // Handle unknown error formats
      alert("An unknown error occurred. Please check the console for details.");
    }
  };
  
  
  
  
  
  
  return (
    <><>
      
    </><div className="max-w-4xl mx-auto p-6">
  
    <TabComponent activeTab={activeTab} setActiveTab={setActiveTab} />
      

        <div className="flex items-center gap-4 mb-6">
           {/* Search input */}
      <input
        type="text"
        placeholder="Search orders..."
        value={searchTerm}
        onChange={handleSearchChange}
      />


      {/* Filter dropdown */}
      <select
        value={filterStatus}
        onChange={(e) => setFilterStatus(e.target.value as OrderStatus)} // Cast value to OrderStatus
        className="border border-gray-300 rounded-lg px-4 py-2"
      >
        <option value="all">All Status</option>
        <option value="delivered">Delivered</option>
        <option value="pending">Pending</option>
        <option value="confirmed">Confirmed</option>
        <option value="declined">Declined</option>
        <option value="cancelled">Cancelled</option>
        <option value="location">Location</option> {/* Location filter */}
      </select>
      

          <button
            className="border bg-amber-500 border-gray-300 rounded-lg px-4 py-2"
            onClick={() => prepareDownloadData(filteredData)}
          >
            Download CSV
          </button>
        </div>

        {successMessage && (
          <div className="mb-4 p-2 bg-green-200 text-green-800 rounded">
            {successMessage}
          </div>
        )}



{successMessage && (
  <div className="mb-4 p-2 bg-green-200 text-green-800 rounded">
    {successMessage}
  </div>
)}

<div className="overflow-x-auto">
  <table className="min-w-full px-5 py-3 bg-amber-200 bg-opacity-25 rounded-tl-xl rounded-tr-xl border border-b-0 text-left text-xs font-semibold text-black uppercase tracking-wider">
    <thead>
      <tr>
        <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S/N</th>
        <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Order Code</th>
        <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Restaurant</th>
        <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date</th>
        <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
        <th className="px-5 py-3 border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Action</th>
      </tr>
    </thead>
    <tbody>
    {paginatedOrders.map((order: Order, index: number) => (
  <tr key={order._id} className="bg-amber-200">
    {/* S/N Column */}
    <td className="px-5 py-3">{(pageNumber - 1) * ordersPerPage + index + 1}</td>
    <td className="px-5 py-3">{order.order_code}</td>
    <td className="px-5 py-3">{order.restaurant_name}</td>
    <td className="px-5 py-3">
      {order.created_at
        ? `${new Date(order.created_at).toLocaleDateString()} ${new Date(order.created_at).toLocaleTimeString()}`
        : 'N/A'}
    </td>
    <td className="px-5 py-3">
      {order.order_status}
      {order.order_status !== "delivered" && order.order_status === "pending" && (
        <>
          <button
            onClick={() => handleAcceptOrder(order)}
            className="text-white bg-green-500 hover:bg-green-600 px-2 py-1 rounded"
            disabled={isUpdating}
          >
            Accept
          </button>
          <button
            onClick={() => handleDeclineOrder(order)}
            className="text-white bg-orange-500 hover:bg-orange-600 px-2 py-1 rounded"
            disabled={isUpdating}
          >
            Decline
          </button>
          <button
            onClick={() => handleCancelOrder(order)}
            className="text-white bg-red-500 hover:bg-red-600 px-2 py-1 rounded"
            disabled={isUpdating}
          >
            Cancel
          </button>
        </>
      )}
    </td>
    <td className="px-5 py-3">
      <button
        className="text-amber-500 px-2 py-1 border border-zinc-400 text-sm rounded"
        onClick={() => openModal(order)}
      >
        View
      </button>
    </td>
  </tr>
))}

    </tbody>
  </table>
</div>


<div className="pagination-controls flex items-center gap-2 mt-4">
  {/* Previous Button */}
  <button
    onClick={() => handlePageChange(pageNumber - 1)}
    disabled={pageNumber === 1}
    className={`px-4 py-2 border rounded-lg transition duration-200 ${pageNumber === 1 ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-gray-100'}`}
  >
    Previous
  </button>

  {/* Page Number Display */}
  <span className="px-4 py-2 border rounded-lg bg-white text-gray-700">
    Page {pageNumber} of {totalPages || 1}
  </span>

  {/* Next Button */}
  <button
    onClick={() => handlePageChange(pageNumber + 1)}
    disabled={pageNumber >= totalPages}
    className={`px-4 py-2 border rounded-lg transition duration-200 ${pageNumber >= totalPages ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-gray-100'}`}
  >
    Next
  </button>
</div>





        {/* Modal for Order Details */}
        {selectedOrder && (
          <div className="fixed inset-0 bg-amber-500 bg-opacity-50  flex items-center justify-center z-50">
            <div
              id="order-details"
              className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full relative max-h-screen overflow-y-auto"
            >
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 bg-yellow-500 text-white py-2 px-4 rounded"
              >
                Close
              </button>
              <h2 className="text-2xl font-bold mb-4">Order Details</h2>

              <div className="space-y-2">
                <div className="flex justify-between border-b pb-2">
                  <p><strong>Order ID:</strong></p>
                  <p>{selectedOrder._id}</p>
                </div>
                <div className="flex justify-between border-b pb-2">
                  <p><strong>Order Code:</strong></p>
                  <p>{selectedOrder.order_code}</p>
                </div>

                <div className="flex justify-between border-b pb-2">
  <p><strong>Date Delivered:</strong></p>
  <p>
    {selectedOrder.date_delivered && 
     new Date(selectedOrder.date_delivered).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.date_delivered).toLocaleDateString()} ${new Date(selectedOrder.date_delivered).toLocaleTimeString()}` 
      : ''}
  </p>
</div>
<div className="flex justify-between border-b pb-2">
  <p><strong>pickup time</strong></p>
  <p>
    {selectedOrder.pickup_time && 
     new Date(selectedOrder.pickup_time).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.pickup_time).toLocaleDateString()} ${new Date(selectedOrder.pickup_time).toLocaleTimeString()}` 
      : ''}
  </p>
</div>

                <div className="flex justify-between border-b pb-2">
                  <p><strong>Order Date:</strong></p>
                  <p>{selectedOrder.created_at ?
                    `${new Date(selectedOrder.created_at).toLocaleDateString()} ${new Date(selectedOrder.created_at).toLocaleTimeString()}`
                    : ' '}</p>
                </div>

                <div className="flex justify-between border-b pb-2">
  <p><strong>created At</strong></p>
  <p>
    {selectedOrder.created_at && 
     new Date(selectedOrder.created_at).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.created_at).toLocaleDateString()} ${new Date(selectedOrder.created_at).toLocaleTimeString()}` 
      : ''}
  </p>
</div>

<div className="flex justify-between border-b pb-2">
  <p><strong>Enroute Time</strong></p>
  <p>
    {selectedOrder.enroute_time && 
     new Date(selectedOrder.enroute_time).getFullYear() !== 1 ? 
      `${new Date(selectedOrder.enroute_time).toLocaleDateString()} ${new Date(selectedOrder.enroute_time).toLocaleTimeString()}` 
      : ''}
  </p>
</div>



                <div className="flex justify-between border-b pb-2">
                  <p><strong>Order Status:</strong></p>
                  <p>{selectedOrder.order_status}</p>
                </div>
                
                <div className="flex justify-between border-b pb-2">
                  <p><strong>Is in Stock:</strong></p>
                  <p>{selectedOrder.is_in_stock}</p>
                </div>
                

                <div className="flex justify-between border-b pb-2">
                  <p><strong>Order Status:</strong></p>
                  <p>{selectedOrder.order_status}</p>
                </div>
                <div className="flex justify-between border-b pb-2">
                  <p><strong>Payment Method:</strong></p>
                  <p>{selectedOrder.payment_method}</p>
                </div>
                
                

                <div className="flex justify-between border-b pb-2">
                  <p><strong>Agent:</strong></p>
                  <Link to={`/pr-admin/agents/agent-details/${selectedOrder.agent}`}>
                    <img src={removeRedEye} alt="View" />
                  </Link>
                </div>
                <div className="flex justify-between border-b pb-2">
                  <p><strong>User:</strong></p>

                  <Link to={`/pr-admin/users/user-details/${selectedOrder.user}`}>

                    <img src={removeRedEye} alt="View" />
                  </Link>
                </div>



                <div className="flex justify-between border-b pb-2">
                  <p><strong>Agent Delivered Order Upload:</strong></p>
                  <p>{selectedOrder.agent_delivered_order_upload}</p>
                </div>
                <div className="flex justify-between border-b pb-2">
                  {selectedOrder && <DeliveryLocation locationId={selectedOrder.delivery_location as string} />}

                </div>
                
              

                <div className="flex justify-between border-b pb-2">
                  <p><strong>Note:</strong></p>
                  <p>{selectedOrder.note}</p>
                </div>
                <div className="flex justify-between border-b pb-2">
                  <p><strong>User Received Order Upload:</strong></p>
                  <p>{selectedOrder.user_received_order_upload}</p>
                </div>
              </div>

              <div className="flex justify-between border-b pb-2">
                  <p><strong>Discount (Order):</strong></p>
                  <p>₦{selectedOrder.discount?.order?.toFixed(2)}</p>
                </div>
                <div className="flex justify-between border-b pb-2">
                  <p><strong>Discount (Delivery):</strong></p>
                  <p>₦{selectedOrder.discount?.delivery?.toFixed(2)}</p>
                </div>
                <div className="flex justify-between border-b pb-2">
          <p><strong>Service Charge:</strong></p>
          <p>{selectedOrder.tax}</p>
        </div>
              <div className="mt-4">

        <ul>
          {selectedOrder.items?.map((item: { meal: { _id: React.Key | null | undefined; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { amount: number; }; }; qty: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => (
            <li key={item.meal._id} className="mb-2">
              <div className="flex justify-between border-b pb-2">
                <p><strong>Meal Name:</strong></p>
                <p>{item.meal?.name}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Description:</strong></p>
                <p>{item.meal?.description}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Quantity:</strong></p>
                <p>{item.qty}</p>
              </div>
              <div className="flex justify-between border-b pb-2">
                <p><strong>Price:</strong></p>
                <p>₦{item.meal?.price.amount.toFixed(2)}</p>
              </div>
              
            </li>
          ))}
        </ul>
      </div>




              <button
                onClick={downloadInvoice}
                className="mt-4 bg-green-500 text-white py-2 px-4 rounded"
              >
                Download Invoice
              </button>
            </div>
          </div>
        )}







      </div></>
  );
};

export default NotificationWrapper;
