
import { request } from "../hooks/api";
import { saveToken } from "../hooks/request";
import { Order } from "./types";
import { Suspension } from './types'; // Adjust the import path as needed
import { MealCategory, OrderStatus } from 'src/services/types'; // Ensure this is the correct path.


interface Admin {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  type: string;
}

interface AdminResponse {
  success: boolean;
  data: Admin[]; // Array of admin objects
  totalPages: number;
}



type QueryParams = Record<string, string | number | boolean>;

class AdminService {
  static getLogisticsManagement(currentPage: number) {
    throw new Error('Method not implemented.');
  }
 
  static deleteMeal(id: string) {
    throw new Error('Method not implemented.');
  }
    static getAgentTransactions(arg0: number, arg1: number, arg2: string, arg3: string, arg4: string) {
        throw new Error('Method not implemented.');
    }
    static getAdmins(currentPage: number, itemsPerPage: number) {
        throw new Error('Method not implemented.');
    }
  static getUserOrders(userId: string | undefined, arg1: number) {
    throw new Error('Method not implemented.');
  }


  
  static getUserDetails(userId: string | undefined) {
    throw new Error('Method not implemented.');
  }
  
  static getRestaurantDetails(restaurantId: string | undefined) {
    throw new Error("Method not implemented.");
  }
  
  static getRestaurants(arg0: number) {
    throw new Error('Method not implemented.');
  }
  
  [x: string]: any;

  async login(email: string, password: string) {
    try {
      const response = await request(
        "/admins/login",
        "POST",
        { email, password },
        false,
        false,
        false
      );
      saveToken(response.token); // Save the token after login
      return response;
    } catch (error) {
      throw error;
    }
  }

  

  async  fetchPaginatedData<T>(
    endpoint: string,
    page: number,
    size: number = 40,
    
    queryParams: QueryParams = {}
  ): Promise<T[]> {
    try {
      // Add page and size to query params
      const params = new URLSearchParams({ page: page.toString(), size: size.toString() });
      
      // Append additional query parameters
      Object.entries(queryParams).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          params.append(key, value.toString());
        }
      });
  
      const queryString = params.toString();
      const response = await request(
        `${endpoint}?${queryString}`,
        "GET",
        {},
        true,
        false,
        false
      );
  
      if (Array.isArray(response)) {
        return response as T[];
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      console.error(`Error fetching data from ${endpoint}:`, error);
      throw error;
    }
  }
  


  async getOrders(
    page: number = 1, // Current page number
    size: number = 40, // Number of items per page (page size)
    restaurant_id?: string,
    order_status?: OrderStatus,
    longitude?: number,
    latitude?: number,
    max_distance: bigint = 100000000000000n // 1200 kilometers in meters
  ): Promise<Order[]> {
    try {
      const defaultLongitude = 8.6753; // Approximate center of Nigeria
      const defaultLatitude = 9.0820;
  
      const queryLongitude = longitude ?? defaultLongitude;
      const queryLatitude = latitude ?? defaultLatitude;
  
      const queryParams: Record<string, string> = {
        max_distance: max_distance.toString(), // Convert bigint to string
        longitude: queryLongitude.toString(), // Ensure longitude is a string
        latitude: queryLatitude.toString(),   // Ensure latitude is a string
        size: size.toString(),                // Convert page size to string
        page: page.toString(),                // Convert page to string
      };
  
      if (restaurant_id) {
        queryParams.restaurant_id = restaurant_id;
      }
  
      if (order_status && order_status !== "all") {
        queryParams.order_status = order_status;
      }
  
      // Make the API call for the current page and page size
      const paginatedOrders = await this.fetchPaginatedData<Order>(
        "/shopping/order/search",
        page,
        size, // Pass the selected page size
        queryParams
      );
  
      return paginatedOrders;
    } catch (error) {
      console.error("Error fetching orders:", error);
      throw error;
    }
  }
  

  

  async getLogisticsManagement(
    page: number,
    longitude?: number,
    latitude?: number,
    max_distance: bigint = 100000000000000n
  ): Promise<any[]> {
    try {
      const defaultLongitude = 8.6753;
      const defaultLatitude = 9.0820;
  
      const queryLongitude = longitude ?? defaultLongitude;
      const queryLatitude = latitude ?? defaultLatitude;
  
      const queryParams = [
        `page=${page}`,
        `size=40`, // Fixed size
        `max_distance=${max_distance}`,
        `longitude=${queryLongitude}`,
        `latitude=${queryLatitude}`,
      ];
  
      const queryString = queryParams.join("&");
  
      const response = await request(
        `/logistics/search?${queryString}`,
        "GET",
        {},
        true,
        false,
        false
      );
  
      console.log("API Response:", response);
  
      if (Array.isArray(response)) {
        return response;
      } else if (response && response._id) {
        return [response];
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching logistics data:", error);
      throw error;
    }
  }
  
  
  

  async getUsers(page: number) {
    try {
      const response = await request(
        `/users?&size=100&page=${page}`,
        "GET",
        {},
        true,
        false,
        false
      );

      console.log('API response:', response);

      if (Array.isArray(response)) {
        return response;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  }


 
  
  
  
  
  
  async searchLogistics(params: any) {
    try {
      const response = await request(
        `/logistics/search?&size=100&${new URLSearchParams(params).toString()}`,
        "GET",
        {},
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getAgents(page: number) {
    try {
      const response = await request(
        `/agents?&size=40&page=1`,
        "GET",
        {},
        true,
        false,
        false
      );

      console.log('API response:', response);

      if (Array.isArray(response)) {
        return response;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching agents:', error);
      throw error;
    }
  }


  async getRestaurants(page: number, itemsPerPage: number): Promise<any> {
    try {
      // Define the endpoint and query parameters
      const url = `/restaurants`;
      const params = new URLSearchParams({ size: itemsPerPage.toString(), page: page.toString() });
  
      // Make the API request using the `request` utility
      const response = await request(`${url}?${params.toString()}`, "GET", {}, true, false, false);
  
      console.log('API response:', response); // Debug the raw response
  
      // Handle the response structure
      if (Array.isArray(response)) {
        // Response is an array; return it directly with inferred total
        return {
          data: response,
          total: response.length, // Use the array length as total
        };
      } else if (response?.data && Array.isArray(response.data)) {
        // Response contains a `data` array; use it with total (if provided)
        return {
          data: response.data,
          total: response.total || response.data.length, // Use length if `total` is not provided
        };
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching restaurants:', error);
      throw error; // Rethrow the error for further handling
    }
  }
  


  
  async getUserTransactions(page: number, size: number, status?: string) {
    try {
      const response = await request(
        `/admins/user-transactions?page=${page}&size=100&status=${status || ''}`,
        "GET",
        {},
        true,
        false,
        false
      );
  
      if (Array.isArray(response)) {
        return response;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching user transactions:', error);
      throw error;
    }
  }
  async getAgentOrders(agentId: string | number, page: number, orderStatus: string) {
    try {
      const response = await request(
        `/shopping/order/search?page=${page}&agentId=${agentId}&order_status=${orderStatus}&size=100`,
        "GET",
        {},
        true,
        false,
        false
      );
  
      if (response && Array.isArray(response.orders)) {
        return { orders: response.orders, totalPages: response.totalPages };
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching agent orders:', error);
      throw error;
    }
  }













   // Debit user wallet
   async debitUserWallet(userId: string, amount: number) {
    try {
      const response = await request(
        `/users/wallet-debit`,
        "PATCH",
        { amount, userId }, // Payload for the request
        true,  // Authenticated request
        false, // No need to process file uploads
        false  // No raw response needed
      );

      if (response && response.success) {
        return response; // Return response if debit is successful
      } else {
        throw new Error('Failed to debit wallet');
      }
    } catch (error) {
      console.error('Error debiting user wallet:', error);
      throw error;
    }
  }

  async debitRestaurantWallet(restaurantId: string, amount: number) {
    try {
      const response = await request(
        `/restaurants/wallet-debit/${restaurantId}`, // Corrected endpoint
        "PATCH",
        { amount }, // Only send the amount in the payload
        true,
        false,
        false
      );
  
      if (response && response.success) {
        return response;
      } else {
        throw new Error('Failed to debit restaurant wallet');
      }
    } catch (error) {
      console.error('Error debiting restaurant wallet:', error);
      throw error;
    }
  }
  

  

  

  
  async getUserDeliveryAddresses(userId: string) {
    try {
      const response = await request(
        `/locations/delivery-addresses?page=1`,
        "GET",
        {},
        true, // Include token for authentication
        false, // Expect JSON response
        false // Not form data
      );
      return response;
    } catch (error) {
      console.error('Error fetching delivery addresses:', error);
      throw error;
    }
  }


  
  async suspendUser(suspensionId: string, userId: string, duration: string, reason: string) {
    try {
      const suspensionData = {
        suspension_id: suspensionId,
        suspend: false,
        suspension_for: "user",
        id: userId,
        reason: reason,
        duration: duration,
      };
      const response = await request(
        `/admins/suspensions/create`,
        "PATCH",
        suspensionData,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }


  

  

  async suspendAgent(agentId: string, duration: string, reason: string) {
    try {
      const suspensionData = {
        suspension_id: agentId,
        suspend: false,
        suspension_for: "agent",
        id: agentId,
        reason: reason,
        duration: duration,
      };
      const response = await request(
        `/admins/suspensions/create`,
        "PATCH",
        suspensionData,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  



   

  // Method to fetch details of a specific agent
  async getAgentDetails(agentId: string) {
    try {
      const response = await request(
        `/agents/${agentId}`,
        "GET",
        {},
        true,
        false,
        false
      );

      console.log('API response:', response);

      if (response && response._id === agentId) {
        return response;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching agent details:', error);
      throw error;
    }
  }

  async updateAgent(agentData: any) {
    try {
      const response = await request(
        "/agents/update",
        "PATCH",
        agentData,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getAppStats() {
    try {
      const response = await request(
        "/app-stats",
        "GET",
        {},
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async creditAgentWallet(amount: number, agentId: string, isWithdrawal: boolean) {
    try {
      const creditData = {
        amount: amount,
        agentId: agentId,
        is_withdrawal: isWithdrawal,
      };
      const response = await request(
        "/agents/wallet/credit",
        "PATCH",
        creditData,
        true, // includeToken
        true, // Expect JSON response
        false // Not form data
      );
      return response;
    } catch (error) {
      console.error('Error in creditAgentWallet:', error);
      throw error;
    }
  }

  

  async createSupportTicket(comment: string) {
    try {
      const response = await request(
        "/supports",
        "POST",
        { comment },
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  

    async checkAgent(agent: { email: string }) {
      try {
        const response = await request(
          `/admins/check-agent?email=${agent.email}`,
          'PATCH',
          { email: agent.email },
          true,
          false,
          false
        );
        return response;
      } catch (error) {
        throw error;
      }
    }
  
  
  

  


  async getLoction() {
    try {
      const response = await request(`/locations/delivery-address/`, "GET", {}, true, false, false);
      console.log('API response:', response);
      if (Array.isArray(response)) {
        return response;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw error;
    }
  }

 
  
  

  // Method to get details of a specific restaurant
async getRestaurantDetails(restaurantId: string) {
  try {
    const response = await request(
      `/restaurants/${restaurantId}`, 
      "GET",
      {},
      true,
      false,
      false
    );
    
    console.log('API response:', response);
    
    if (response && response._id === restaurantId) {
      return response;
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (error) {
    console.error('Error fetching restaurant details:', error);
    throw error;
  }
}

async checkRestaurant({ email }: { email: string }) {
  console.log('Sending request to verify restaurant with email:', email); // Log email value

  try {
    const response = await request(
      '/admins/check-restaurant',  // URL
      'PATCH',                           // HTTP method
      { email },                         // Body (email data)
      true,                              // Authenticated request (maybe to include token)
      false,                             // Some flag (maybe for logging or retries)
      false                              // Some other flag (depends on your custom request logic)
    );

    // Check if response is a valid object and has a json method
    if (response && typeof response.json === 'function') {
      const data = await response.json();
      //console.log('API response:', data);

      return data;
    } else {
      // Handle the case when the response is not in expected format
      console.error(response);
     // throw new Error('Unexpected response format');
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
     // console.error('Error fetching restaurant verification:', error.message);
      throw new Error(`Restaurant verification: ${error.message}`);
    } else {
      console.error('Unexpected error:', error);
      throw new Error('An unknown error occurred during verification');
    }
  }
}

  



  
  
  async getRestaurantOrders(restaurant_name: string, restaurant_id: String) {
    try {
      const response = await request(
        `/shopping/order/search?page=1&restaurant_name=${restaurant_name}&restaurant_id=${restaurant_id}&userId=&agentId=&order_status=pending&cancelled&delivered&confirmed&deliveredId=&size=10000000000000000000000`,
        "GET",
        {},
        true,
        false,
        false
      );
  
      if (response && Array.isArray(response.orders)) {
        return response.orders;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching restaurant orders:', error);
      throw error;
    }
  }
  


  
  
  
  

  

  async getAdmins(): Promise<Admin[]> {
    try {
      const response = await request('/admins?page=1', 'GET', {}, true, false, false);
  
      // If the response is a single object, convert it to an array
      if (response && typeof response === 'object' && !Array.isArray(response)) {
        return [response]; // Wrap the single object in an array
      } else if (Array.isArray(response)) {
        return response;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching admins:', error);
      throw error;
    }
  }
  
 
  
 
 
  async createAdmin(data: { first_name: string; last_name: string; email: string; password: string; phone: string; }) {
    try {
      const response = await request(
        `/admins/create`,
        "POST",
        data,  // Use data as payload for the POST request
        true,
        false,
        false
      );
  
      if (response && response.success) {
        return response;
      } else {
        throw new Error('Failed to create admin');
      }
    } catch (error) {
      console.error('Error creating admin:', error);
      throw error;
    }
  }

  // Method to fetch suspension history/admins/suspensions?page=${page}
  
  

  async getSuspensionHistory(page: number): Promise<Suspension[]> {
    try {
      const response = await request('/admins/suspensions?page=1', 'GET', {}, true, false, false);
  
      // If the response is a single object, convert it to an array
      if (response && typeof response === 'object' && !Array.isArray(response)) {
        return [response]; // Wrap the single object in an array
      } else if (Array.isArray(response)) {
        return response;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching admins:', error);
      throw error;
    }
  }





  async createSuspension(data: { type: string; suspendee_id: string; reason: string; duration: string }) {
    try {
      const response = await request(
        `/admins/suspensions/create`,
        "POST",
        data, // Use data as payload for the POST request
        true,
        false,
        false
      );

      if (response && response.success) {
        return response;
      } else {
        throw new Error('Failed to create suspension');
      }
    } catch (error) {
      console.error('Error creating suspension:', error);
      throw error;
    }
  }

  
 

  async deleteAdmin(id: string) {
    try {
      const response = await request(
        `/admins/delete/${id}`,
        "DELETE",
        {},
        true,
        false,
        false
      );
  
      if (response && response.success) {
        return response;
      } else {
        throw new Error('Failed to delete admin');
      }
    } catch (error) {
      console.error('Error deleting admin:', error);
      throw error;
    }
  }
  

//implementation of comfirmed,declined 
async updateOrders(orderId: string, updateData: Partial<Order>) {
  try {
    const url = `/admins/orders/update/${orderId}`;

    // Make sure updateData is passed as a proper object for JSON serialization
    const response = await request(
      url,
      "PATCH",
      updateData, // Passing directly as JSON body
      true, // Include Authorization header
      false, // Use default base URL
      false  // Do not override headers
    );

    return response;
  } catch (error) {
    console.error("Error updating orders:", error);
    throw error;
  }
}






 


async getMealCategories(): Promise<MealCategory[]> {
    try {
      const response = await request('/meals/categories', 'GET', {}, true, false, false);
      if (response && Array.isArray(response)) {
        return response;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching meal categories:', error);
      throw error;
    }
  }

  async createMealCategory(name: string) {
    try {
      const response = await request(
        '/meals/category/create',
        'POST',
        { name }, // Sending category name as payload
        true,
        false,
        false
      );

      if (response && response.success) {
        return response;
      } else {
        throw new Error('category created successfully');
      }
    } catch (error) {
      console.error('category created successfully', error);
      throw error;
    }
  }


  
  
  async deleteMeal(_id: string) {
    try {
      const response = await request(
        `/meals/delete/${_id}`,
        "DELETE",
        {}, // No body for DELETE request
        true, // Token should be included
        false,
        false
      );
  
      console.log('Delete response:', response); // Log the response for debugging
      return response; // Adjust based on your response structure
    } catch (error) {
      console.error('Error deleting meal category:', error);
      // Optionally rethrow the error to keep the original context
      throw error instanceof Error ? error : new Error('An unknown error occurred while deleting the meal category.');
    }
  }
  
  
  






async assignAgentToOrder(data: { order_id: string; agent_id: string; parcel_id?: string }) {
  try {
    const response = await request(
      '/admins/order/assign-agent',  // API endpoint
      'PATCH',                       // Method
      { 
        order_id: data.order_id,
        agent_id: data.agent_id,
        parcel_id: data.parcel_id,  // Optional field
      }, 
      true,                          // Token should be included
      false,                         // No file upload
      false                          // Not raw request
    );

    console.log('API response:', response);
    return response; // Adjust based on your response structure
  } catch (error) {
    console.error('Error assigning agent:', error);
    throw error instanceof Error ? error : new Error('An unknown error occurred while assigning the agent.');
  }
}




// Broadcast a delivery request
async broadcastDeliveryRequest(data: { latitude: number; longitude: number; order_id: string; parcel_id?: string }) {
  try {
    const response = await request(
      `/shopping/delivery-request`, // API endpoint
      "POST",
      data, // Request payload
      true,  // Authenticated request
      false, // No file upload
      false  // Not raw
    );

    if (response) {
      console.log('API response:', response);
      return response;  // Handle the response appropriately
    } else {
      throw new Error('Broadcast request failed');
    }
  } catch (error) {
    console.error('Error broadcasting delivery request:', error);
    throw error;
  }
}





// method for searching agents
async searchAgents(longitude: number, latitude: number, distance: number, limit: number) {
  try {
    const response = await request(
      `/locations/agents?longitude=${longitude}&latitude=${latitude}&distance=${distance}&limit=${limit}`, 
      "GET", 
      {}, 
      true, 
      false, 
      false
    );
    
    console.log('API response:', response);
    if (Array.isArray(response)) {
      return response;
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (error) {
    console.error('Error fetching agents:', error);
    throw error;
  }
}

async getAgentTransactions(page: number, size: number, status?: string, agentId?: string, type?: string) {
  try {
    const response = await request(
      `/admins/agent-transactions?page=${page}&size=${size}&status=${status || ''}&agentId=${agentId || ''}&type=${type || ''}`,
      "GET",
      {},
      true,
      false,
      false
    );

    if (Array.isArray(response)) {
      return response;
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (error) {
    console.error('Error fetching agent transactions:', error);
    throw error;
  }
}


async getAgentCashPaymentWallet(agentId: string) {
  try {
    const response = await request(
      `/admins/cash-payment-wallets/${agentId}`,
      "GET",
      {},
      true,
      false,
      false
    );

    if (response) {
      return response;
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (error) {
    console.error('Error fetching agent cash payment wallet:', error);
    throw error;
  }
}


async getCashPaymentTransactions(page: number, size: number, agentId?: string, status?: string, start_date?: string, end_date?: string) {
  try {
    const response = await request(
      `/admins/cash-payment-wallet/transactions?page=${page}&size=${size}&agentId=${agentId || ''}&status=${status || ''}&start_date=${start_date || ''}&end_date=${end_date || ''}`,
      "GET",
      {},
      true,
      false,
      false
    );

    if (Array.isArray(response)) {
      return response;
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (error) {
    console.error('Error fetching transactions:', error);
    throw error;
  }
}



// Debit agent wallet
async debitWallet(amount: number, agentId: string) {
  try {
    const response = await request(
      '/admins/cash-payment-wallet/wallet-debit',
      'PATCH',
      { amount, agentId },
      true,
      false,
      false
    );
    if (response && response.success) {
      return response; // Return response if debit is successful
    } else {
      throw new Error('Failed to debit wallet');
    }
  } catch (error) {
    console.error('Error debiting user wallet:', error);
    throw error;
  }
}

}






export default AdminService;