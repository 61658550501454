import React, { useEffect, useState } from 'react';
import { AppLayout } from 'src/components/layouts';
import AdminService from 'src/services/admin.services';
import { Restaurant } from '../../services/types';
import SearchInput from '../../components/ui/SearchInput';
import { useParams } from 'react-router-dom';
import removeRedEye from '../../assets/icons/removeRedEye.svg';

const adminService = new AdminService();

const RestaurantsManagement: React.FC = () => {
  const [data, setData] = useState<Restaurant[]>([]);
  const [filteredData, setFilteredData] = useState<Restaurant[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [tab, setTab] = useState<number>(0); // Manage tabs
  const itemsPerPage = 40;

  const fetchData = async (page: number) => {
    try {
      setIsLoading(true);
      const response = await adminService.getRestaurants(page, itemsPerPage); // Adjusted API request
      const restaurants = response.data;
      setData(restaurants);
      setFilteredData(restaurants);
      setTotalPages(Math.ceil(response.total / itemsPerPage)); // Assuming the response contains total for pagination
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error fetching restaurants:', error.message);
        setError(error.message);
      } else {
        console.error('Unexpected error:', error);
        setError('An unexpected error occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage + 1);  // Fetch with adjusted page
  }, [currentPage]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredData.length / itemsPerPage)); // Recalculate total pages whenever filtered data changes
  }, [filteredData]);

  const handleSearch = (searchTerm: string) => {
    if (searchTerm) {
      const filtered = data.filter((restaurant) =>
        restaurant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        restaurant.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
      setCurrentPage(0); // Reset to first page
    } else {
      setFilteredData(data);
      setCurrentPage(0); // Reset to first page
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1); // Use functional updates
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1); // Use functional updates
    }
  };

  return (
    <AppLayout>
      <div className="grid lg:grid-cols-2 gap-4">
        <div className="lg:col-span-3">
          <div className="bg-white shadow-lg rounded-md p-8">
            <h2 className="text-xl font-semibold mb-4">Restaurant Management</h2>

            {/* Tab Switcher */}
            <div className="flex space-x-4 mb-6">
              <div
                onClick={() => setTab(0)}
                className={`cursor-pointer py-2 px-4 text-center ${tab === 0 ? 'border-b-2 border-pr-primary' : 'text-gray-500'}`}
              >
                Restaurants
              </div>
            </div>

            {/* Tab Content */}
            {tab === 0 && (
              <>
                <div className="mb-4">
                  <SearchInput search={handleSearch} placeholder="Search restaurants..." />
                </div>
                {isLoading ? (
                  <div className="flex justify-center">Loading...</div>
                ) : error ? (
                  <div className="text-red-500">{error}</div>
                ) : filteredData.length === 0 ? (
                  <div className="text-gray-500">No restaurants found.</div>
                ) : (
                  <div className="min-w-full leading-normal bg-amber-200 bg-opacity-25 rounded-tl-xl rounded-tr-xl border rounded-xl">
                    <div className="flex justify-between items-center p-4">
                      <div className="flex items-center">
                        Filter by:
                        <select
                          className="border border-gray-300 rounded px-2 py-1"
                          value="all"
                          onChange={(event) => console.log(event.target.value)}
                        >
                          <option value="all">All</option>
                          <option value="day">Day</option>
                          <option value="week">Week</option>
                          <option value="month">Month</option>
                          <option value="year">Year</option>
                        </select>
                      </div>
                      <button onClick={() => console.log('Download CSV')}>
                        <i className="fas fa-download"></i> Download CSV
                      </button>
                    </div>
                    <div className="overflow-x-auto">
                      <table className="min-w-full px-5 py-3 bg-amber-200 bg-opacity-25 rounded-tl-xl rounded-tr-xl border border-b-0 text-left text-xs font-semibold text-black uppercase tracking-wider">
                        <thead>
                          <tr>
                            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                              S/N
                            </th>
                            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                              Name
                            </th>
                            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                              Email
                            </th>
                            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                              Status
                            </th>
                            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                              Address
                            </th>
                            <th className="px-5 py-3 bg-amber-200 bg-opacity-25 border border-zinc-400 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                              View Details
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData
                            .slice(currentPage * itemsPerPage, currentPage * itemsPerPage + itemsPerPage) // Get visible data for current page
                            .map((restaurant, index) => (
                              <tr key={restaurant._id} className="hover:bg-gray-50">
                                <td className="px-5 py-3 border border-zinc-400 text-sm">
                                  {currentPage * itemsPerPage + index + 1}
                                </td>
                                <td className="px-5 py-3 border border-zinc-400 text-sm">
                                  {restaurant.name}
                                </td>
                                <td className="px-5 py-3 border border-zinc-400 text-sm">
                                  {restaurant.email}
                                </td>
                                <td className="px-5 py-3 border border-zinc-400 text-sm">
                                  {restaurant.is_activated ? 'Active' : 'Inactive'}
                                </td>
                                <td className="px-5 py-3 border border-zinc-400 text-sm">
                                  <p className="text-gray-600 text-lg">{restaurant.address}</p>
                                </td>
                                <td className="px-5 py-3 border border-zinc-400 text-sm">
                                  <a href={`/pr-admin/restaurants/restaurant-details/${restaurant._id}`}>
                                    <img src={removeRedEye} alt="View Details" />
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    {/* Pagination */}
                    <div className="flex justify-between items-center py-4">
                      <button
                        type="button"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 0}
                        className={`h-full flex items-center justify-center p-2 rounded border ${currentPage === 0 ? 'bg-gray-300 text-gray-500' : 'bg-primary text-white'}`}
                      >
                        Previous
                      </button>

                      <button
                        type="button"
                        onClick={handleNextPage}
                        disabled={currentPage >= totalPages - 1}
                        className={`h-full flex items-center justify-center p-2 rounded border ${currentPage >= totalPages - 1 ? 'bg-gray-300 text-gray-500' : 'bg-primary text-white'}`}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default RestaurantsManagement;
